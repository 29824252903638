@import "color";
@import "font";
@import "title";
@import "buttons";
@import "container";
@import "form";
@import "modal";
@import "header";
@import "main";
@import "footer";


* {
  outline: none;
  box-sizing: border-box;

  &:after,
  &:before {
    transition: inherit;
  }
}

html {
  scroll-behavior: smooth;
}


body {
  font-family: "Inter", "Inter Tight", 'FF HalvarBreit-XBd', 'FF HalvarBreit-Md', sans-serif;
  background-color: $body;
  color: $dark;
  overflow-x: hidden;
}

:root {
  font-size: 10px;
}

h1, h2, h3, h4, h5, h6, p, ul, li {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  cursor: pointer;
  transition: .3s;
}

input,
button,
textarea {
  outline: none;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  transition: .3s;
  outline: 0;
}

.strong {
  font-weight: 700;
}

.video-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  line-height: 100%;
  color: $white;
  transition: .3s;

  &:before {
    content: '';
    position: absolute;
    background-image: url("../img/play-btn.svg");
    background-size: contain;
    background-repeat: no-repeat;
    width: 75%;
    height: 100%;
    z-index: -5;
  }
}
