@import "color";

.primary {
  text-align: center;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: $red;
  color: $white;
  transition: .3s;
  &:hover {
    background: #ff0000;
  }
}

.whatsapp-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  background-color: $green;
  border-radius: 5px;
  font-size: 1.7rem;
  font-weight: 600;
  line-height: 150%;
  color: $dark;
  transition: .3s;
  &:hover {
    background: #28c235;
  }
  @media screen and (max-width: 1199px) {
    max-width: 308px;
  }

  @media screen and (max-width: 767px) {
    font-size: 1.4rem;
  }
  .btn-icon {
    width: 26px;
    height: 26px;
    margin-right: 6px;
    object-fit: contain;
  }
}

.certificate-btn {
  position: absolute;
  top: 180px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  background-color: $white;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.15);
  z-index: 15;
  &:before {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    top: 50%;
  }
  @media screen and (max-width: 1199px) {
    display: none;
  }
}

.certificate-btn-next {
  left: 10px;
  &:before {
    border-left: 1px solid $red;
    border-bottom: 1px solid $red;
    transform: translate(0, -50%) rotate(45deg);
    left: 15px;
  }
}

.certificate-btn-prev {
  right: 10px;
  &:before {
    border-right: 1px solid $red;
    border-bottom: 1px solid $red;
    transform: translate(0, -50%) rotate(-45deg);
    right: 15px;
  }

}

