@import "color";

.header {

  .container {
    @media screen and (max-width: 425px) {
      padding: 0 10px;
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 0;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    @media screen and (max-width: 960px) {
      border-bottom: none;
    }
  }

  &__logo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-width: 220px;
    @media screen and (max-width: 767px) {
      max-width: 120px;
    }
  }

  .logo-img {
    display: block;
    width: 100px;
    height: 100px;
    object-fit: contain;
    @media screen and (max-width: 767px) {
      width: 54px;
      height: 54px;
    }
  }

  .logo-text {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .logo-title {
    font-family: 'FF HalvarBreit-Md', sans-serif;
    font-style: normal;
    font-size: 2.6rem;
    font-weight: 700;
    line-height: 100%;
    text-transform: uppercase;
    color: $dark;
    @media screen and (max-width: 767px) {
      font-size: 1.4rem;
    }
  }

  &__menu {
    margin-left: 48px;
    margin-right: auto;
    @media screen and (max-width: 960px) {
      position: absolute;
      top: 115px;
      left: 0;
      width: 100%;
      margin-left: 0;
      border-top: 1px solid rgba(0, 0, 0, .1);
      border-bottom: 1px solid rgba(0, 0, 0, .1);
    }
    @media screen and (max-width: 767px) {
      overflow: auto;
      top: 70px;
    }
    &.active {
      position: fixed;
      top: 0;
      background-color: $body;
      z-index: 20;
    }
  }

  .menu-list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 265px;
    @media screen and (max-width: 960px) {
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      max-width: 100%;
      height: 35px;
      padding: 0 20px;
    }
    @media screen and (max-width: 767px) {
      width: 155vw;
      max-width: 600px;
      margin: 0 auto;
      overflow: auto;

    }
  }

  .list-item {
    width: fit-content;
    margin-bottom: 8px;
    margin-right: 16px;
    &:nth-child(3) {
      margin-right: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    @media screen and (max-width: 960px) {
      margin: 0;
    }
  }

  .item-link {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 140%;
    color: $dark;
    transition: .3s;
    &:hover {
      color: $red;
    }
    @media screen and (max-width: 767px) {
      font-size: 1.4rem;
      font-weight: 500;
    }
  }

  &__address {
    display: flex;
    align-items: center;
    margin-right: 36px;
    @media screen and (max-width: 1199px) {
      display: none;
    }
  }

  .address-col {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: fit-content;
    &:first-child {
      margin-right: 20px;
    }
  }

  .col-title {
    font-size: 1.3rem;
    font-weight: 800;
    line-height: 150%;
  }

  .col-desc {
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 150%;
  }

  .col-btn {
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 150%;
    color: $red;
    transition: .3s;
    &:hover {
      color: $dark;
    }
  }

  &__contact {
    display: flex;
    flex-direction: column;
    align-items: end;
    @media screen and (max-width: 960px) {
      margin-left: auto;
    }
  }

  .contact-phone {
    font-size: 2.6rem;
    font-weight: 700;
    line-height: 140%;
    color: $dark;
    transition: .3s;
    &:hover {
      color: $red;
    }
    @media screen and (max-width: 767px) {
      font-size: 1.2rem;
    }
  }

  .contact-desc {
    margin-bottom: 10px;
    font-size: 1.1rem;
    font-weight: 500;
    line-height: 150%;
    color: $dark;
    opacity: .4;
    @media screen and (max-width: 767px) {
      font-size: 0.8rem;
    }
  }

  .contact-email {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 140%;
    color: $dark;
    transition: .3s;
    &:hover {
      color: $red;
    }
    @media screen and (max-width: 767px) {
      font-size: 1rem;
    }
  }

  &__lang {
    position: absolute;
    top: 50%;
    right: -60px;
    transform: translate(0, -50%);
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    overflow: hidden;
    border: 1px solid #101A1B;
    @media screen and (max-width: 1400px) {
      position: static;
      top: auto;
      right: auto;
      transform: none;
      margin-left: 20px;
    }
    @media screen and (max-width: 767px) {
      margin-left: 15px;
    }
  }

  .lang-item {
    padding: 7px;
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 140%;
    cursor: pointer;
    color: #101A1B;
    transition: 0s;
    &.active {
      background: #101A1B;
      color: #F5F5F5;
    }
    @media screen and (max-width: 767px) {
      padding: 5px;
      font-size: 0.9rem;
    }
  }

}