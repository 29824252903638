@import "color";

h1.title {
  font-family: 'FF HalvarBreit-Blk', sans-serif;
  font-size: 6rem;
  font-weight: 900;
  line-height: 100%;
  text-transform: uppercase;

  @media screen and (max-width: 767px) {
    font-size: 3.1rem;
  }
}

h2.title {
  font-family: 'FF HalvarBreit-Blk', sans-serif;
  font-size: 5.5rem;
  font-weight: 900;
  line-height: 100%;
  text-transform: uppercase;
  @media screen and (max-width: 767px) {
    font-size: 2.4rem;
  }
}

h3.title {
  font-family: 'FF HalvarBreit-Blk', sans-serif;
  font-size: 4.2rem;
  font-weight: 900;
  line-height: 100%;
  text-transform: uppercase;
  @media screen and (max-width: 767px) {
    font-size: 2.4rem;
  }
}

