@import "color";

@font-face {
  font-family: 'FF HalvarBreit-Md';
  font-style: normal;
  font-weight: 400;
  src: local('FF HalvarBreit-Md'), url('../fonts/HalvarBreit-Md.woff') format('woff');
}

@font-face {
  font-family: 'FF HalvarBreit-XBd';
  font-style: normal;
  font-weight: 900;
  src: local('FF HalvarBreit-XBd'), url('../fonts/HalvarBreit-XBd.woff') format('woff');
}

@font-face {
  font-family: 'FF HalvarBreit-Blk';
  font-style: normal;
  font-weight: 900;
  src: local('FF HalvarBreit-Blk'), url('../fonts/HalvarBreit-Blk.woff') format('woff');
}
