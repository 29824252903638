@import "color";

.main {
  padding-top: 46px;
  @media screen and (max-width: 1199px) {
    padding-bottom: 113px;
  }
  @media screen and (max-width: 767px) {
    padding-bottom: 30px;
  }

  .wrapper {
    position: relative;
    display: flex;
    @media screen and (max-width: 1199px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1199px) {
      align-items: center;
      margin-bottom: 58px;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 28px;
    }
  }

  .title {
    max-width: 650px;
  }

  .text-paragraph {
    max-width: 445px;
    margin-top: 14px;
    margin-bottom: 14px;
    font-size: 3rem;
    font-weight: 500;
    line-height: 130%;

    @media screen and (max-width: 767px) {
      font-size: 1.9rem;
    }
  }

  .btn-block {
    display: flex;
    align-items: center;
    position: absolute;
    top: 390px;
    transition: .3s;
    @media screen and (max-width: 1199px) {
      position: relative;
      top: auto;
      justify-content: center;
      width: 100%;
      z-index: 10;
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 400px;
        background: linear-gradient(0deg, #EEECE7 0%, rgba(238, 236, 231, 0.00) 100%);
        z-index: 1;
        transition: .3s;
      }
    }
    @media screen and (max-width: 767px) {
      position: fixed;
      bottom: 10px;
      flex-direction: column;
      align-items: center;
      &:before {
        display: none;
      }
    }
  }

  .primary {
    padding: 26px;
    margin-right: 24px;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 150%;
    @media screen and (max-width: 1199px) {
      max-width: 746px;
      width: 100%;
      margin-right: 0;
      z-index: 5;
    }
    @media screen and (max-width: 767px) {
      order: 2;
      max-width: 340px;
      padding: 13px;
      font-size: 1.3rem;
    }
  }

  .btn-desc {
    display: block;
    max-width: 225px;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 150%;
    @media screen and (max-width: 1199px) {
      display: none;
    }
    @media screen and (max-width: 767px) {
      display: block;
      order: 1;
      z-index: 5;
      font-size: 1.1rem;
      color: $body;
      margin-bottom: 7px;
      transition: transform 0.5s, opacity 0.5s;
    }
  }

  &__background {
    position: relative;
    width: 45%;
    height: 800px;
    margin-right: 35px;
    @media screen and (max-width: 1199px) {
      width: 100%;
      max-width: 735px;
      height: 624px;
      margin: 0;
    }
    @media screen and (max-width: 767px) {
      height: 500px;
      transition: .5s;
      &:before {
        content: '';
        position: absolute;
        bottom: -5px;
        left: 0;
        width: 100%;
        height: 180px;
        background: linear-gradient(0deg, #EEECE7 0%, rgba(238, 236, 231, 0.00) 100%);
        z-index: 1;
      }
    }
  }

  .background-img {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    @media screen and (max-width: 1199px) {
      width: 85%;
      left: 50%;
      transform: translate(-50%, 0);
      object-fit: cover;
      object-position: top;
    }
    @media screen and (max-width: 767px) {
      width: 95%;
      left: auto;
      right: 0;
      transform: none;
    }
  }

  .decor-wrapper {
    position: absolute;
    top: 100px;
    right: -35px;
    z-index: 1;
    @media screen and (max-width: 1199px) {
      top: 65px;
      right: 20px;
    }
    @media screen and (max-width: 767px) {
      top: 0;
      right: 0;
    }
  }

  .background-decor {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 192px;
    height: 192px;
    border-radius: 50%;
    padding: 20px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    @media screen and (max-width: 767px) {
      width: 136px;
      height: 136px;
    }
  }

  .decor-1 {
    background-color: $yellow;
    @media screen and (max-width: 767px) {
      opacity: 0;
      transform: translateY(50px);
      transition: transform 0.5s, opacity 0.5s;
    }
  }

  .decor-2 {
    margin-top: -20px;
    background-color: $blue;
    @media screen and (max-width: 767px) {
      opacity: 0;
      transform: translateY(50px);
      transition: transform 0.5s, opacity 0.5s;
    }
  }

  .decor-img {
    display: block;
    width: 40px;
    height: 26px;
    margin-bottom: 12px;
    object-fit: contain;
    @media screen and (max-width: 767px) {
      width: 28px;
      height: 18px;
    }
  }

  .decor-desc {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 140%;
    @media screen and (max-width: 767px) {
      font-size: .9rem;
    }
  }

  .decor-title {
    display: block;
    padding: 4px 7px;
    margin-bottom: 12px;
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 140%;
    @media screen and (max-width: 767px) {
      padding: 2px 4px;
      font-size: .9rem;
    }
  }
  .advantage-list {
    position: absolute;
    bottom: 37px;
    left: 0;
    display: flex;
    align-items: end;
    @media screen and (max-width: 1199px) {
      position: static;
      margin-top: 67px;

    }
    @media screen and (max-width: 767px) {
      margin-top: 30px;
    }
  }

  .list-item {
    display: flex;
    flex-direction: column;
    margin-right: 40px;
    &:last-child {
      margin-right: 0;
    }
    @media screen and (max-width: 1199px) {
      align-items: center;
    }
    @media screen and (max-width: 767px) {
      width: 110px;
      height: 150px;
      margin-right: 10px;
    }
  }

  .item-img {
    display: block;
    width: fit-content;
    height: 75px;
    object-fit: contain;
    @media screen and (max-width: 1199px) {
      margin-bottom: 15px;
    }
    @media screen and (max-width: 767px) {
      height: fit-content;
    }

  }

  .img-1 {
    @media screen and (max-width: 767px) {
      width: 37px;
    }
  }

  .img-2 {
    @media screen and (max-width: 767px) {
      width: 55px;
    }
  }

  .img-3 {
    @media screen and (max-width: 767px) {
      width: 28px;
    }
  }

  .item-desc {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 140%;
    @media screen and (max-width: 767px) {
      font-size: 1.2rem;
    }
  }
}

.calculation {

  @media screen and (max-width: 767px) {
    padding-bottom: 20px;
  }

}

.video {
  padding-top: 65px;
  padding-bottom: 60px;
  @media screen and (max-width: 1199px) {
    padding-top: 40px;
  }
  @media screen and (max-width: 767px) {
    padding-top: 20px;
    padding-bottom: 40px;
  }

  &__wrapper {
    position: relative;
    display: block;
    width: 100%;
    height: 657px;
    border-radius: 5px;
    overflow: hidden;
    @media screen and (max-width: 1199px) {
      height: 450px;
    }

    @media screen and (max-width: 767px) {
      height: 186px;
    }
  }

  &__background {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .video-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 235px;
    height: 205px;
    font-size: 2.7rem;
    &:before {
      content: '';
      position: absolute;
      left: 35px;
      background-image: url("../img/play-btn.svg");
      background-size: contain;
      background-repeat: no-repeat;
      width: 75%;
      height: 100%;
      z-index: -5;
      @media screen and (max-width: 1199px) {
        left: 25px;
      }
      @media screen and (max-width: 767px) {
        left: 15px;
      }
    }
    @media screen and (max-width: 1199px) {
      width: 162px;
      height: 142px;
      font-size: 2rem;
    }
    @media screen and (max-width: 767px) {
      width: 97px;
      height: 85px;
      font-size: 1.2rem;
    }

  }

  &__subtitle {
    display: block;
    max-width: 520px;
    margin-top: 15px;
    color: $dark;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 110%;
    @media screen and (max-width: 767px) {
      max-width: 320px;
      font-size: 1.5rem;
    }
  }
}

.catalog {
  padding-top: 60px;
  padding-bottom: 50px;
  @media screen and (max-width: 1199px) {
    padding-bottom: 40px;
  }

  @media screen and (max-width: 767px) {
    padding-top: 40px;
    padding-bottom: 10px;
  }

  .title {
    @media screen and (max-width: 1199px) {
      text-align: center;
    }
  }

  &__advantage {
    display: flex;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 70px;
    @media screen and (max-width: 1199px) {
      justify-content: space-between;
      max-width: 650px;
      margin: 60px auto 85px;
    }

    @media screen and (max-width: 767px) {
      margin: 25px auto 40px;
    }
  }

  .advantage-item {
    margin-right: 40px;
    @media screen and (max-width: 1199px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      width: 100%;
      max-width: 190px;
      margin-right: 0;
    }

    @media screen and (max-width: 767px) {
      max-width: 110px;
    }
  }

  .advantage-icon {
    width: 38px;
    height: 38px;
    object-fit: contain;
    @media screen and (max-width: 767px) {
      width: 20px;
      height: 20px;
    }
  }

  .advantage-desc {
    font-size: 2rem;
    font-weight: 600;
    line-height: 150%;
    @media screen and (max-width: 1199px) {
      font-size: 1.8rem;
    }

    @media screen and (max-width: 767px) {
      font-size: 1.2rem;
    }
  }

  &__wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1199px) {
      max-width: 820px;
      margin: 0 auto;
    }
    @media screen and (max-width: 767px) {
      max-width: 340px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 67.2%;
    @media screen and (max-width: 1199px) {
      width: 100%;
      order: 1;
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
      max-width: 340px;
      margin: 0 auto;
    }

    @media screen and (max-width: 425px) {
      max-width: 100%;
    }
  }

  .list-item {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 49%;
    margin-bottom: 20px;
    border-radius: 5px;
    overflow: hidden;
    @media screen and (max-width: 767px) {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 5px;
      background-color: $white;
    }

    @media screen and (max-width: 425px) {
      &:last-child {
        margin-bottom: 20px;
      }
    }
  }

  .item-mark {
    position: absolute;
    top: 12px;
    right: 12px;
    display: block;
    max-width: 115px;
    padding: 5px 8px 5px 23px;
    border-radius: 4px;
    background-color: $white;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 150%;
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 8px;
      transform: translate(0, -50%);
      width: 9px;
      height: 9px;
      border-radius: 50%;
      background-color: #6A9534;
    }
    @media screen and (max-width: 767px) {
      max-width: 60px;
      padding: 3px 5px;
      border: 1px solid #6A9534;
      font-size: .9rem;
      color: #6A9534;
      &:before {
        display: none;
      }
    }
  }

  .img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 235px;
    background-color: rgba(224, 220, 210, 1);
    @media screen and (max-width: 767px) {
      align-items: end;
      width: 45%;
      height: auto;
      margin-right: 15px;
      order: 2;
      background-color: transparent;
    }
  }

  .item-img {
    display: block;
    width: 70%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    @media screen and (max-width: 767px) {
      width: 100%;
      height: 80%;
    }
  }

  .item-desc {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 20px 20px 30px;
    background-color: $white;
    @media screen and (max-width: 767px) {
      width: 55%;
      height: auto;
      padding: 15px 10px;
      order: 1;
    }
  }

  .item-title {
    font-size: 3rem;
    font-weight: 700;
    line-height: 110%;
    @media screen and (max-width: 767px) {
      font-size: 1.6rem;
    }
  }

  .item-subtitle {
    margin-top: 5px;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 150%;
    @media screen and (max-width: 767px) {
      font-size: 1rem;
    }
  }

  .desc-list {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;
    @media screen and (max-width: 767px) {
      margin-bottom: 15px;
    }
  }

  .list-col {
    width: 50%;
    &:first-child {
      margin-right: 50px;
      @media screen and (max-width: 767px) {
        margin-right: 10px;
      }
    }
  }

  .desc-title {
    margin-top: 20px;
    font-size: 1.3rem;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0.26px;
    text-transform: uppercase;
    opacity: .3;
    @media screen and (max-width: 767px) {
      margin-top: 10px;
      font-size: .8rem;
    }
  }

  .desc-name {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 150%;
    @media screen and (max-width: 767px) {
      font-size: 1.1rem;
    }
  }

  .item-link {
    display: block;
    position: relative;
    padding-left: 30px;
    margin-top: auto;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 150%;
    color: $dark;
    cursor: pointer;
    @media screen and (max-width: 767px) {
      padding-left: 0;
      font-size: 1.1rem;
    }
    &:before {
      content: '';
      position: absolute;
      background-image: url("../img/cert-icon.svg");
      background-size: contain;
      background-repeat: no-repeat;
      width: 20px;
      height: 28px;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }

  &__additionally {
    display: flex;
    flex-direction: column;
    width: 67.2%;
    padding: 24px 20px;
    border-radius: 5px;
    background-color: $white;
    @media screen and (max-width: 1199px) {
      width: 100%;
      order: 3;
    }

    @media screen and (max-width: 767px) {
      padding: 24px 15px;
    }
  }
  .additionally-title {
    max-width: 574px;
    font-size: 4rem;
    font-weight: 700;
    line-height: 100%;
    @media screen and (max-width: 767px) {
      font-size: 2rem;
    }
  }
  .additionally-subtitle {
    max-width: 574px;
    margin-top: 17px;
    margin-bottom: 30px;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 150%;
    @media screen and (max-width: 767px) {
      font-size: 1.4rem;
    }
  }
  .additionally-list {
    display: grid;
    grid-template-columns: 100px 100px 100px 100px 100px;
    grid-template-rows: max-content max-content;
    gap: 20px 35px;
    grid-template-areas:
    ". . . . ."
    ". . . . .";
    max-width: 640px;
    @media screen and (max-width: 767px) {
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: max-content max-content max-content;
      gap: 15px 15px;
      grid-template-areas:
    ". . ."
    ". . ."
    ". . .";
      font-size: 2rem;
      max-width: 100%;
    }
  }
  .additionally-item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .additionally-img {
    width: 100px;
    height: 100px;
    object-fit: contain;
    @media screen and (max-width: 767px) {
      width: 100%;
      height: 90px;
    }
  }
  .additionally-desc {
    text-align: center;
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 150%;
    @media screen and (max-width: 767px) {
      font-size: 1.2rem;
    }
  }


  &__sidebar {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    width: 27.9%;
    height: 100%;
    @media screen and (max-width: 1199px) {
      position: static;
      width: 100%;
      margin: 40px 0;
      order: 2;
    }

    @media screen and (max-width: 767px) {
      margin: 20px 0;
    }
    @media screen and (max-width: 425px) {
      margin-top: 0;
    }
  }

  .sticky-block {
    position:-webkit-sticky;
    position:sticky;
    top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 1199px) {
      position: static;
    }
  }

  .form-wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px 15px 27px;
    border-radius: 5px;
    background-color: $white;
    @media screen and (max-width: 1199px) {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      padding: 20px 72px 40px 15px;
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: center;
      padding: 20px 15px;
    }

    .title-wrapper {
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 1199px) {
        max-width: 335px;
      }
    }

    .form-title {
      font-size: 2.5rem;
      font-weight: 700;
      line-height: 110%;
      @media screen and (max-width: 767px) {
        font-size: 2rem;
      }
    }

    .form-subtitle {
      margin-top: 10px;
      margin-bottom: 20px;
      font-size: 1.7rem;
      font-weight: 600;
      line-height: 150%;
      @media screen and (max-width: 767px) {
        margin-bottom: 0;
        font-size: 1.4rem;
      }
    }
  }

  .form-body {
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1199px) {
      width: 100%;
      max-width: 308px;
    }

    .form-input {
      padding: 10px 0;
      margin-top: 20px;
      font-size: 1.5rem;
      color: $dark;
      border-radius: 0;
      border-bottom: 1px solid rgba(16, 26, 27, 0.40);
      &::placeholder {
        color: $dark;
        opacity: 0.3;
      }
    }

    .upload-btn {
      margin: 25px 0;
    }

    .form-submit {
      padding: 20px;
      font-size: 1.7rem;
      font-weight: 600;
      line-height: 150%;
      @media screen and (max-width: 767px) {
        font-size: 1.4rem;
      }
    }
  }
  .or {
    margin: 14px 0;
    font-size: 1.7rem;
    font-weight: 600;
    line-height: 150%;
  }

  .info {
    max-width: 250px;
    margin-top: 10px;
    text-align: center;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 150%;
    @media screen and (max-width: 767px) {
      font-size: 1.3rem;
    }
  }
}

.promo {
  padding-top: 50px;
  padding-bottom: 60px;
  @media screen and (max-width: 1199px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media screen and (max-width: 767px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .promo-wrapper {
    position: relative;
    padding: 35px 25px 50px;
    border-radius: 5px;
    background-color: $dark;
    color: $white;
    @media screen and (max-width: 1199px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 820px;
      margin: 0 auto;
    }

    @media screen and (max-width: 767px) {
      padding: 20px 20px 30px;
    }
  }

  .promo-desc {
    max-width: 530px;
    @media screen and (max-width: 1199px) {
      order: 2;
      text-align: center;
    }
  }

  .subtitle {
    margin-top: 10px;
    margin-bottom: 30px;
    font-size: 2.8rem;
    font-weight: 500;
    line-height: 110%;
    @media screen and (max-width: 767px) {
      font-size: 1.4rem;
      margin-bottom: 20px;
    }
  }

  .desc-list {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    margin-bottom: 27px;
    @media screen and (max-width: 1199px) {
      padding-left: 0;
      align-items: center;
    }
  }

  .list-item {
    margin-bottom: 7px;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 150%;
    list-style: disc;
    @media screen and (max-width: 767px) {
      font-size: 1.4rem;
    }
  }

  .promo-btn {
    padding: 20px 26px;
    font-size: 1.7rem;
    font-weight: 600;
    line-height: 150%;
    @media screen and (max-width: 425px) {
      width: 100%;
    }
  }

  .promo-decor {
    position: absolute;
    right: -30px;
    bottom: 0;
    width: 653px;
    height: 550px;
    overflow: hidden;
    @media screen and (max-width: 1199px) {
      position: static;
      order: 1;
      overflow: visible;
      width: 397px;
      height: 379px;
      margin-top: -110px;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 767px) {
      width: 237px;
      height: 237px;
      margin-top: -50px;
    }
  }

  .decor-img {
    position: absolute;
    bottom: -90px;
    width: 100%;
    @media screen and (max-width: 1199px) {
      position: static;
      width: 100%;
      height: 100%;
    }
  }
}

.quality {
  padding: 60px 0;
  overflow: hidden;
  @media screen and (max-width: 1199px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  @media screen and (max-width: 767px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .quality-title {
    font-family: 'FF HalvarBreit-Md', sans-serif;
    font-weight: 400;

    .strong-title {
      display: block;
      font-family: 'FF HalvarBreit-Blk', sans-serif;
    }
    @media screen and (max-width: 1199px) {
      text-align: center;
    }
  }

  &__advantage {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    max-width: 795px;
    margin-top: 50px;
    @media screen and (max-width: 1199px) {
      width: 100%;
      max-width: 650px;
      margin: 63px auto 0;
    }
    @media screen and (max-width: 767px) {
      margin-top: 38px;
    }
  }

  .advantage-item {
    display: flex;
    flex-direction: column;
    max-width: 286px;
    &:nth-child(2) {
      max-width: 193px;
      @media screen and (max-width: 1199px) {
        max-width: 190px;
      }
      @media screen and (max-width: 767px) {
        max-width: 110px;
      }
    }
    &:nth-child(3) {
      max-width: 217px;
      @media screen and (max-width: 1199px) {
        max-width: 190px;
      }
      @media screen and (max-width: 767px) {
        max-width: 110px;
      }
    }
    @media screen and (max-width: 1199px) {
      align-items: center;
      height: max-content;
      max-width: 190px;
    }
    @media screen and (max-width: 767px) {
      max-width: 110px;
    }
  }

  .item-img {
    display: block;
    width: fit-content;
    height: 84px;
    @media screen and (max-width: 767px) {
      height: 46px;
    }
  }

  .item-desc {
    margin-top: 20px;
    font-size: 2rem;
    font-weight: 600;
    line-height: 150%;
    @media screen and (max-width: 1199px) {
      text-align: center;
    }
    @media screen and (max-width: 767px) {
      font-size: 1.2rem;
    }
  }

  .background {
    position: relative;
    top: -80px;
    width: 100%;
    height: 1020px;
    z-index: -20;
    @media screen and (max-width: 1199px) {
      top: 0;
    }
    @media screen and (max-width: 767px) {
      height: 595px;
    }
  }

  .background-decor {
    position: absolute;
    top: -67px;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 276px;
    height: 276px;
    border-radius: 50%;
    padding: 38px 27px;
    background: $red-gradient;
    box-shadow: $red-shadow;
    @media screen and (max-width: 1199px) {
      top: 15px;
      right: 55px;
    }
    @media screen and (max-width: 767px) {
      width: 159px;
      height: 159px;
      padding: 20px 15px;
      top: 210px;
      right: 0;
    }
  }

  .decor-img {
    display: block;
    width: 63px;
    height: 45px;
    margin-bottom: 24px;
    @media screen and (max-width: 767px) {
      width: 36px;
      height: 25px;
      margin-bottom: 13px;
    }
  }

  .decor-desc {
    color: #E4E6E8;
    text-align: center;
    font-size: 2rem;
    font-weight: 600;
    line-height: 150%;
    @media screen and (max-width: 767px) {
      font-size: 1.1rem;
    }
  }

  .background-img {
    position: absolute;
    top: 0;
    left: -10vw;
    z-index: -1;
    @media screen and (max-width: 1199px) {
      left: 0;
    }
    @media screen and (max-width: 767px) {
      width: 1462px;
      height: 595px;
      left: -670px;
    }
  }

  &__list {
    display: flex;
    align-items: start;
    justify-content: space-between;
    margin-top: -190px;
    @media screen and (max-width: 1199px) {
      flex-direction: column;
      align-items: center;
      max-width: 820px;
      margin: -150px auto 0;
    }
  }

  .list-title {
    max-width: 360px;
    font-size: 3rem;
    font-weight: 700;
    line-height: 120%;
    @media screen and (max-width: 1199px) {
      max-width: 100%;
      margin-bottom: 43px;
      font-weight: 500;
    }
    @media screen and (max-width: 767px) {
      margin-bottom: 22px;
      font-size: 1.7rem;
      text-align: center;
    }

  }

  .list-card {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    max-width: 820px;
    width: 100%;
  }

  .card-item {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 48.8%;
  }

  .card-img {
    display: block;
    width: 100%;
    height: 555px;
    object-fit: cover;
    @media screen and (max-width: 767px) {
      height: 200px;
    }
  }

  .card-title {
    position: relative;
    margin-top: 20px;
    margin-bottom: 15px;
    padding-left: 18.5px;
    font-size: 2rem;
    font-weight: 700;
    line-height: 110%;
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      background-repeat: no-repeat;
      background-size: contain;
    }
    @media screen and (max-width: 767px) {
      font-size: 1.4rem;
    }
  }

  .incorrect {
    color: #D80501;
    &:before {
      width: 12px;
      height: 12px;
      background-image: url("../img/incorrect.svg");
    }
  }

  .correct {
    color: #30971E;
    &:before {
      width: 14px;
      height: 10px;
      background-image: url("../img/correct.svg");
    }
  }

  .card-desc {
    max-width: 97.5%;
    margin-bottom: 10px;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 150%;
    @media screen and (max-width: 767px) {
      font-size: 1.2rem;
    }
  }

  .card-label {
    position: absolute;
    top: -50px;
    right: -35px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    padding: 26px;
    background: $green-gradient;
    box-shadow: $green-shadow;
    @media screen and (max-width: 960px) {
      right: 0;
    }
    @media screen and (max-width: 767px) {
      top: -14px;
      width: 82px;
      height: 82px;
    }

  }
  .label-title {
    color: #FFF;
    text-align: center;
    font-size: 6.3rem;
    font-weight: 700;
    line-height: 120%;
    @media screen and (max-width: 767px) {
      font-size: 3rem;
    }
  }

  .label-desc {
    color: #FFF;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 120%;
    @media screen and (max-width: 767px) {
      font-size: .8rem;
    }
  }

  .tour-factory{
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 530px;
    padding: 30px 70px 30px 25px;
    margin-top: 120px;
    border-radius: 5px;
    overflow: hidden;
    color: $white;
    @media screen and (max-width: 1199px) {
      flex-direction: column;
      align-items: center;
      text-align: center;
      max-width: 820px;
      height: auto;
      padding: 0;
      margin: 80px auto 0;
      border-radius: 5px;
      background: linear-gradient(0deg, #101A1B 0%, #101A1B 100%), lightgray 0px -50.393px / 145.455% 95.744% no-repeat;
    }
  }

  .tour-text {
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 1199px) {
      align-items: center;
      max-width: 615px;
      margin-top: 55px;
    }

    @media screen and (max-width: 767px) {
      padding: 0 20px;
      margin-top: 30px;
    }
  }

  .tour-subtitle {
    display: block;
    max-width: 360px;
    margin-top: 10px;
    font-size: 2rem;
    font-weight: 600;
    line-height: 150%;
    @media screen and (max-width: 1199px) {
      max-width: 510px;
      margin-bottom: 40px;
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 25px;
      font-size: 1.2rem;
    }
  }

  .tour-form {
    display: flex;
    flex-direction: column;
    max-width: 420px;
    @media screen and (max-width: 1199px) {
      align-items: center;
      justify-content: center;
      text-align: center;
      max-width: 100%;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: 320px;
      padding: 0 10px;
      margin-bottom: 55px;
    }
  }

  .form-title {
    font-size: 3rem;
    font-weight: 700;
    line-height: 110%;
    @media screen and (max-width: 767px) {
      font-size: 1.4rem;
    }
  }

  .form-subtitle {
    display: block;
    margin-top: 5px;
    margin-bottom: 25px;
    font-size: 2rem;
    font-weight: 600;
    line-height: 150%;
    @media screen and (max-width: 1199px) {
      max-width: 360px;
    }
    @media screen and (max-width: 767px) {
      max-width: 280px;
      margin-bottom: 13px;
      font-size: 1.2rem;
      opacity: 0.4;
    }
  }

  .form-body {
    display: flex;
    flex-direction: column;
    width: 100%;
    @media screen and (max-width: 1199px) {
      align-items: center;
    }

    .form-input {
      padding: 20px 25px;
      margin-top: 10px;
      background-color: $white;
      font-size: 2rem;
      font-weight: 600;
      line-height: 150%;
      color: $dark;
      &::placeholder {
        color: $dark;
      }
      &:first-child {
        margin-top: 0;
      }
      @media screen and (max-width: 767px) {
        width: 100%;
        padding: 18px;
        font-size: 1.4rem;
        margin-top: 5px;
      }
    }

    .form-submit {
      max-width: 315px;
      padding: 26px;
      margin-top: 22px;
      font-size: 2rem;
      font-weight: 600;
      line-height: 150%;
      @media screen and (max-width: 767px) {
        max-width: 100%;
        width: 100%;
        padding: 18px;
        margin-top: 15px;
        font-size: 1.4rem;
      }
    }
  }

  .video-link {
    position: absolute;
    bottom: 30px;
    left: 10px;
    display: flex;
    align-items: center;
    color: $white;
    z-index: 5;
    @media screen and (max-width: 1199px) {
      flex-direction: column;
      bottom: 200px;
      left: 50%;
      transform: translate(-50%, 0);
    }

    @media screen and (max-width: 767px) {
      bottom: 35px;
    }
  }

  .video-button {
    position: relative;
    width: 132px;
    height: 117px;
    font-size: 1.6rem;
    &:before {
      content: '';
      position: absolute;
      left: 20px;
      background-image: url("../img/play-btn.svg");
      background-size: contain;
      background-repeat: no-repeat;
      width: 75%;
      height: 100%;
      z-index: -2;
      @media screen and (max-width: 767px) {
        left: 15px;
      }
    }
    @media screen and (max-width: 1199px) {
      order: 2;
    }

    @media screen and (max-width: 767px) {
      width: 100px;
      height: 88px;
      font-size: 1.2rem;
    }
  }

  .link-desc {
    font-size: 2rem;
    font-weight: 600;
    line-height: 150%;
    @media screen and (max-width: 1199px) {
      order: 1;
      margin-bottom: 80px;
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 30px;
      font-size: 1.4rem;
    }
  }

  .background-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -5;
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(16,26,27, .5);
      z-index: 5;
    }

    @media screen and (max-width: 1199px) {
      position: relative;
      transform: none;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 529px;
      z-index: 0;
      &:before {
        background: linear-gradient(180deg, #101A1B 0%, rgba(16, 26, 27, 0.00) 61.28%);
      }
    }

    @media screen and (max-width: 767px) {
      height: 195px;
    }
  }

  .tour-background {
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

}

.certificate {
  padding: 60px 0;
  overflow: hidden;
  @media screen and (max-width: 1199px) {
    padding: 70px 0;
  }
  @media screen and (max-width: 767px) {
    padding: 40px 0;
  }
  .title {
    max-width: 1010px;
    @media screen and (max-width: 1199px) {
      max-width: 790px;
      margin: 0 auto;
      text-align: center;
    }

    @media screen and (max-width: 767px) {

    }
  }

  .subtitle {
    display: block;
    max-width: 595px;
    margin-top: 20px;
    margin-bottom: 45px;
    font-size: 2rem;
    font-weight: 600;
    line-height: 150%;
    @media screen and (max-width: 1199px) {
      text-align: center;
      margin: 20px auto 60px;
    }
    @media screen and (max-width: 767px) {
      max-width: 310px;
      margin: 15px auto 25px;
      font-size: 1.4rem;
    }
  }

  &__list {
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .slide-link {
    color: $dark;
  }

  .slide-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .slide-title {
    margin-top: 10px;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 150%;
    @media screen and (max-width: 767px) {
      font-size: 1.2rem;
    }
  }

  &__lab,
  &__callback {
    display: flex;
    //justify-content: space-between;
    margin-top: 80px;
    @media screen and (max-width: 1199px) {
      flex-direction: column;
      max-width: 820px;
      margin:  60px auto 0;
    }

    @media screen and (max-width: 767px) {
      margin-top: 40px;
    }
  }

  .lab-img {
    width: 56%;
    height: 450px;
    object-fit: cover;
    @media screen and (max-width: 1199px) {
      height: 545px;
    }

    @media screen and (max-width: 767px) {
      height: 225px;
    }
  }

  .lab-desc {
    width: 40%;
    margin-left: 30px;
    @media screen and (max-width: 1199px) {
      max-width: 630px;
      width: 100%;
      margin-top: 20px;
      margin-left: 0;
    }
  }

  .desc-title {
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    @media screen and (max-width: 767px) {
      font-size: 1.6rem;
    }
  }

  .desc-subtitle {
    display: block;
    max-width: 400px;
    margin-top: 15px;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 150%;
    @media screen and (max-width: 767px) {
      font-size: 1.2rem;
    }
  }

  .subtitle-2 {
    max-width: 300px;
  }

  &__callback {
    margin-top: 20px;
    @media screen and (max-width: 1199px) {
      margin-top: 60px;
    }
    @media screen and (max-width: 767px) {
      margin-top: 30px;
    }
  }

  .callback-img {
    display: block;
    width: 56%;
    height: 627px;
    object-fit: cover;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    @media screen and (max-width: 1199px) {
      height: 630px;
    }

    @media screen and (max-width: 767px) {
      height: auto;
    }
  }

  .callback-desc {
    width: 40%;
    margin-left: 30px;
    @media screen and (max-width: 1199px) {
      max-width: 630px;
      width: 100%;
      margin-top: 20px;
    }

    @media screen and (max-width: 767px) {

    }
  }

  .lab-img,
  .callback-img {
    @media screen and (max-width: 1199px) {
      width: 100%;
    }

    @media screen and (max-width: 767px) {

    }

  }
}

.delivery {
  position: relative;
  padding: 60px 0;
  @media screen and (max-width: 1199px) {
    padding: 70px 0;
  }
  @media screen and (max-width: 767px) {
    padding: 40px 0;
  }

  &__wrapper {
    width: 100%;
    max-width: 1390px;
    padding: 30px 25px 0;
    margin: 0 auto;
    color: $white;
    @media screen and (max-width: 1199px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    @media screen and (max-width: 767px) {
      color: $dark;
    }
  }

  .delivery-title {
    @media screen and (max-width: 767px) {
      br {
        display: none;
      }
    }
  }

  .subtitle {
    display: block;
    max-width: 573px;
    margin-top: 25px;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    @media screen and (max-width: 767px) {
      max-width: 256px;
      margin-top: 10px;
      margin-bottom: 28px;
      font-size: 1.4rem;
    }
  }

  &__background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1000px;
    z-index: -5;
    @media screen and (max-width: 767px) {
      position: relative;
      top: auto;
      left: auto;
      height: 254px;
      z-index: 0;
    }

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(0deg, #EEECE7 0%, rgba(238, 236, 231, 0.00) 27.63%), linear-gradient(153deg, #101A1B 6.5%, rgba(16, 26, 27, 0.00) 51.86%);
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }
  .background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -5;
    object-fit: cover;
    @media screen and (max-width: 767px) {
      padding: 0;
    }
  }

  .form-block {
    margin-top: 528px;
    @media screen and (max-width: 1199px) {
      max-width: 820px;
      padding-bottom: 78px;
      margin: 470px auto 0;
    }

    .title {

    }

    @media screen and (max-width: 767px) {
      margin-top: 30px;
    }

    .form-body {
      @media screen and (max-width: 1199px) {
        margin-bottom: 0;
      }
    }

    .form-block__bg {
      @media screen and (max-width: 1199px) {
        display: none;
      }
    }
  }
}

.awards {
  padding: 60px 0;
  overflow: hidden;
  @media screen and (max-width: 1199px) {
    padding: 70px 0;
  }
  @media screen and (max-width: 767px) {
    padding: 40px 0;
  }

  .title {
    max-width: 895px;
    @media screen and (max-width: 1199px) {
      text-align: center;
      margin: 0 auto;
    }

    @media screen and (max-width: 767px) {

    }
  }

  .subtitle {
    display: block;
    max-width: 615px;
    margin-top: 20px;
    font-size: 2rem;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0;
    @media screen and (max-width: 1199px) {
      text-align: center;
      margin: 20px auto 0;
    }

    @media screen and (max-width: 767px) {
      font-size: 1.1rem;
    }
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    @media screen and (max-width: 1199px) {
      flex-direction: column;
      align-items: center;
      margin-top: 60px;
      margin-bottom:90px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 30px;
      margin-bottom: 40px;
    }
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 56.5%;
    @media screen and (max-width: 1199px) {
      width: 100%;
      max-width: 650px;
    }

    @media screen and (max-width: 767px) {
      max-width: 350px;
    }
  }

  .list-item {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    max-width: 210px;
    margin-bottom: 50px;
    @media screen and (max-width: 1199px) {
      align-items: center;
      max-width: 190px;
      text-align: center;
    }

    @media screen and (max-width: 767px) {
      max-width: 110px;
      margin-bottom: 20px;
    }
  }

  .item-img {
    display: block;
    width: 55px;
    height: 38px;
    margin-bottom: 15px;
    object-fit: contain;
  }

  .item-desc {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0;
    @media screen and (max-width: 767px) {
      font-size: 1.2rem;
    }
  }

  &__background {
    display: flex;
    flex-direction: column;
    width: 35%;
    @media screen and (max-width: 1199px) {
      width: 100%;
      max-width: 700px;
    }
  }

  .background-img {
    display: block;
    width: 100%;
    margin-bottom: 15px;
    object-fit: contain;
    border-radius: 0 0 5px 5px;
    @media screen and (max-width: 1199px) {
      margin-bottom: 30px;
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 10px;
    }
  }

  .background-subtitle {
    position: relative;
    width: fit-content;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 150%;
    color: $dark;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: rgba(0, 0, 0, 0.1);
    }
    @media screen and (max-width: 767px) {
      font-size: 1.2rem;
    }
  }

  .clients-slider {
    width: 100%;
    margin-top: 60px;
    @media screen and (max-width: 1199px) {
      margin-top: 90px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 40px;
    }
  }

  .slider-title {
    font-size: 2.8rem;
    font-weight: 700;
    line-height: 110%;
    @media screen and (max-width: 1199px) {
      display: block;
      text-align: center;
    }

    @media screen and (max-width: 767px) {
      font-size: 1.4rem;
    }
  }

  .clients-list {
    width: auto;
    margin-top: 20px;
    .swiper-wrapper {
      display: flex;
      align-items: center;
      @media screen and (max-width: 767px) {
        flex-wrap: wrap;
        justify-content: space-between;
        max-width: 320px;
        margin: 15px auto 0;
      }
      @media screen and (max-width: 320px) {
        max-width: 300px;
      }
    }

    .swiper-slide {
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      @media screen and (max-width: 767px) {
        margin-bottom: 20px;
        &:first-child {
          margin-left: 30px;
        }
        &:nth-child(4) {
          margin-right: 30px;
        }
        &:last-child {
          margin: 0 auto;
        }
      }
    }
  }

  .slide-img {
    display: block;
    height: 90px;
    object-fit: contain;
    @media screen and (max-width: 767px) {
      height: 45px;
    }
  }
}

.cases {
  padding: 60px 0;
  overflow: hidden;
  @media screen and (max-width: 1199px) {
    padding: 70px 0;
  }
  @media screen and (max-width: 767px) {
    padding-top: 0;
    padding-bottom: 40px;
  }

  .container {
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
    }
  }

  .title {
    margin-bottom: 30px;
    @media screen and (max-width: 1199px) {
      text-align: center;
    }

    @media screen and (max-width: 767px) {
      order: 2;
      margin-bottom: 20px;
    }
  }

  .cases-list {
    width: 100%;
    @media screen and (max-width: 1199px) {
      overflow-y: auto;
    }
    @media screen and (max-width: 767px) {
      order: 3;
    }
  }

  .list-wrapper {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1199px) {
      width: 3600px;
    }
    @media screen and (max-width: 767px) {
      width: 2295px;
    }
  }

  .list-col {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 32.5%;
    height: fit-content;
    @media screen and (max-width: 1199px) {
      flex-direction: row;
      width: fit-content;
    }

    @media screen and (max-width: 767px) {

    }
  }

  .case-item {
    display: flex;
    flex-direction: column;
    height: fit-content;
    padding: 20px 20px 30px;
    margin-bottom: 15px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 1);
    @media screen and (max-width: 1199px) {
      width: 390px;
      margin-right: 15px;
      cursor: pointer;
    }

    @media screen and (max-width: 767px) {
      width: 250px;
      padding: 12px 12px 20px;
      margin-right: 5px;
    }
  }

  .case-img {
    width: 100%;
    height: 240px;
    border-radius: 120px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    object-fit: cover;
    object-position: center;
    @media screen and (max-width: 767px) {
      height: 150px;
    }
  }

  .case-title {
    display: block;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 2.6rem;
    font-weight: 700;
    line-height: 110%;
    @media screen and (max-width: 767px) {
      font-size: 1.6rem;
    }
  }

  .case-subtitle {
    display: block;
    margin-bottom: 20px;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 150%;
    opacity: .3;
    @media screen and (max-width: 767px) {
      font-size: 1rem;
    }
  }

  .case-desc {
    display: block;
    margin-bottom: 10px;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 150%;
    @media screen and (max-width: 767px) {
      font-size: 1rem;
    }
  }

  .case-sum {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .sum-item {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .sum-title {
    font-size: 4rem;
    font-weight: 400;
    line-height: 110%;
    @media screen and (max-width: 767px) {
      font-size: 2.5rem;
    }
  }

  .sum-subtitle {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 150%;
    opacity: .3;
    @media screen and (max-width: 767px) {
      font-size: 0.9rem;
    }
  }

  .letter-list {
    margin-top: 55px;
    @media screen and (max-width: 1199px) {
      margin-top: 90px;
    }

    @media screen and (max-width: 767px) {
      order: 1;
      margin-top: 0;
      margin-bottom: 80px;
    }
  }

  .letter-img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.contacts {
  padding: 60px 0 97px;
  @media screen and (max-width: 1199px) {
    padding: 70px 0 120px;
  }
  @media screen and (max-width: 767px) {
    padding: 40px 0;
  }

  &__wrapper {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 1199px) {
      flex-direction: column;
      align-items: center;
    }

    @media screen and (max-width: 767px) {

    }
  }

  &__left {
    display: flex;
    flex-direction: column;
    max-width: 508px;
    @media screen and (max-width: 1199px) {
      align-items: center;
      text-align: center;
      margin-bottom: 75px;
    }

    @media screen and (max-width: 767px) {
      margin-bottom: 30px;
    }
  }

  .title {

  }

  .subtitle {
    margin-top: 20px;
    margin-bottom: 40px;
    font-size: 2rem;
    font-weight: 600;
    line-height: 150%;
    @media screen and (max-width: 1199px) {
      margin-top: 15px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 10px;
      margin-bottom: 30px;
      font-size: 1.4rem;
    }
  }

  .phone {
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 120%;
    color: $dark;
    @media screen and (max-width: 767px) {
      font-size: 2.6rem;
    }
  }

  .time {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 150%;
    @media screen and (max-width: 767px) {
      margin-top: 7px;
      font-size: 1.2rem;
      opacity: .4;
    }
  }

  .email {
    margin-top: 20px;
    margin-bottom: 45px;
    font-size: 3.5rem;
    font-weight: 700;
    line-height: 120%;
    color: $dark;
    @media screen and (max-width: 767px) {
      margin-bottom: 30px;
      font-size: 2.6rem;
    }
  }

  .callback-btn {
    max-width: 335px;
    padding: 26px;
    margin-bottom: 10px;
    background-color: $dark;
    border-radius: 5px;
    font-size: 2rem;
    font-weight: 600;
    line-height: 150%;
    color: $white;
    transition: .3s;
    &:hover {
      background-color: $white;
      color: $dark;
      -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4) inset;
      -moz-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4) inset;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.4) inset;
    }
    @media screen and (max-width: 767px) {
      padding: 18px 26px 18px 26px;
    }
  }

  .whatsapp-btn {
    max-width: 335px;
    padding: 26px;
    font-size: 2rem;
    @media screen and (max-width: 767px) {
      padding: 18px 26px 18px 26px;
    }
  }

  &__right {
    display: flex;
    flex-direction: column;
    max-width: 625px;
    @media screen and (max-width: 1199px) {
      width: 100%;
      max-width: 820px;
    }

    @media screen and (max-width: 767px) {

    }
  }

  .right-item {
    position: relative;
    &:first-child {
      margin-bottom: 40px;
      @media screen and (max-width: 767px) {
        margin-bottom: 30px;
      }
    }
  }

  .item-img {
    width: 100%;
    height: 345px;
    margin-bottom: 15px;
    border-radius: 5px;
    object-fit: cover;
    @media screen and (max-width: 767px) {
      height: 210px;
    }
  }

  .item-title {
    font-size: 1.8rem;
    line-height: 150%;
    @media screen and (max-width: 767px) {
      font-size: 1.4rem;
    }
  }

  .popup-btn {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 10px 15px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    background-color: $white;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 150%;
    @media screen and (max-width: 767px) {
      padding: 9px 11px 9px 11px;
      font-size: 1.2rem;
    }
  }
}