@import "color";

.footer {

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
    border-top: 1px solid rgba(16, 26, 27, .1);
    @media screen and (max-width: 767px) {
      justify-content: center;
      flex-direction: column;
    }
  }

  .footer-logo {

  }

  .logo-title {
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 150%;
    @media screen and (max-width: 767px) {
      font-size: 1.4rem;
    }
  }

  .info-wrapper {
    display: flex;
    align-items: center;
    @media screen and (max-width: 1199px) {
      flex-direction: column;
      align-items: start;
    }

    @media screen and (max-width: 767px) {
      align-items: center;
    }
  }

  .info-item {
    margin-right: 10px;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 150%;
    @media screen and (max-width: 1199px) {
      margin-right: 0;
    }

    @media screen and (max-width: 767px) {
      font-size: 1.4rem;
    }
  }

  .diple {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 150%;
    color: $dark;
    border-bottom: 1px solid rgba(16, 26, 27, .1);
    @media screen and (max-width: 767px) {
      margin-top: 20px;
    }
  }

  .footer-btn {
    padding: 10px 15px;
    border-radius: 5px;
    background-color: $dark;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 150%;
    color: $white;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}