@import "color";

.modal-bg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(60px);
  display: none;
  z-index: 100;
  &.active {
    display: block;
  }
}

.modal {
  width: calc(100% - 40px);
  max-width: 470px;
  max-height: 80vh;
  overflow-y: auto;
  padding: 32px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  border-radius: 6px;
  background-color: #FFF;
  transition: .3s;
  z-index: 110;

  &.active {
    transform: translate(-50%, -50%) scale(1);
  }

  .modal-close {
    position: relative;
    display: block;
    width: 30px;
    height: 30px;
    margin-left: auto;

    &:before,
    &:after {
      content: '';
      position: absolute;
      width: 16px;
      height: 2px;
      top: 50%;
      left: 50%;
      background-color: #000;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(45deg);
    }
  }
}

.modal-calculation,
.modal-callback {
  max-width: 670px;
  padding: 40px;
  border-radius: 20px;
  background-color: $dark;
  @media screen and (max-width: 767px) {
    padding: 30px;
  }
  .modal-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .modal-title {
    font-size: 3rem;
    font-weight: 900;
    line-height: 110%;
    text-align: center;
    text-transform: uppercase;
    color: $white;
    @media screen and (max-width: 767px) {
      max-width: 380px;
      font-size: 2.2rem;
    }
  }

  .modal-subtitle {
    display: block;
    margin-top: 20px;
    margin-bottom: 37px;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 130%;
    text-align: center;
    color: $white;
    @media screen and (max-width: 767px) {
      margin-top: 15px;
      margin-bottom: 20px;
      font-size: 1.4rem;
    }
  }

  .form-body {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: 380px;
    }
  }

  .form-input {
    width: 100%;
    max-width: 380px;
    padding: 20px;
    margin-bottom: 10px;
    font-size: 1.8rem;
    @media screen and (max-width: 767px) {
      padding: 13px 16px;
      font-size: 1.4rem;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &::placeholder {
      font-size: 1.8rem;
      @media screen and (max-width: 767px) {
        font-size: 1.4rem;
      }
    }
  }

  .upload-btn {
    margin-top: 10px;
    margin-right: auto;
  }

  .upload-icon {
    width: 26px;
    height: 26px;
    &:before {
      height: 3px;
    }

    &:after {
      width: 3px;
    }
  }

  .upload-name {
    font-size: 1.8rem;
    color: $white;
  }

  .form-submit {
    padding: 26px;
    margin-top: 30px;
    font-weight: 600;
    line-height: 150%;
    font-size: 2rem;
    @media screen and (max-width: 767px) {
      padding: 20px;
      font-size: 1.4rem;
    }
  }
}


.modal-callback {

  .modal-title {
    font-weight: 400;
  }

  .strong {
    display: block;
  }
  .form-submit {
    margin-top: 0;
  }
}

.modal-video {
  height: 315px;
  padding: 0;
  overflow: visible;
}

.modal-thanks,
.modal-error {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  .img-wrap {
    width: 86px;
    height: 86px;
    padding: 7px;
    margin-bottom: 25px;
    border-radius: 50%;
    border: 3px solid $red;

  }

  &__img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  &__text {
    text-align: center;
    margin-top: 15px;
    margin-bottom: 25px;
    font-size: 2rem;
    line-height: 2.4rem;
  }

}

.modal-btn {
  padding: 20px;
  font-size: 1.8rem;
}

.modal-error {
  .img-wrap {
    position: relative;
    &:before {
      content: '!';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 6rem;
      color: $red;
    }
  }
}

.map-modal {
  width: 100%;
  max-width: 700px;
  max-height: 80vh;
  padding: 40px 15px 15px;

  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
  }
}