$body: #EEECE7;
$red: #DE0000;
$green: #36D145;
$dark: #101A1B;
$white: #FFF;

$yellow: #F1E8B8;
$blue: #BDD9ED;

$yellow-gradient: linear-gradient(196deg, rgba(255, 255, 255, 0.20) 12.81%, rgba(255, 255, 255, 0.00) 88.73%), #F1E8B8;

$red-gradient: linear-gradient(180deg, #DF4B0C 0%, #D70000 100%);
$red-shadow: 0px 4px 100px 0px rgba(150, 4, 4, 0.30);

$green-gradient: linear-gradient(180deg, #7EB12A 0%, #30971E 100%);
$green-shadow: (0px 4px 100px rgba(58, 202, 48, 0.30));
