@import "color";

.form-block {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 30px 25px;
  background-color: $dark;
  color: $white;
  overflow: hidden;
  border-radius: 5px;
  @media screen and (max-width: 1199px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 0 0;
  }
  @media screen and (max-width: 767px) {
    padding: 40px 20px 0;
  }

  .title {
    max-width: 500px;
    @media screen and (max-width: 1199px) {
      text-align: center;
      margin-bottom: 38px;
    }
  }

  .form-body {
    display: flex;
    flex-direction: column;
    max-width: 420px;
    width: 100%;
    margin-bottom: 50px;
    margin-right: 80px;
    z-index: 5;
    @media screen and (max-width: 1199px) {
      margin-right: 0;
      margin-bottom: 292px;
    }
  }

  .form-title {
    margin-bottom: 27px;
    font-size: 2.2rem;
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    @media screen and (max-width: 767px) {
      font-size: 1.4rem;
    }
  }

  .form-input {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .upload-btn {
    margin-top: 23px;
    margin-bottom: 35px;
  }

  .form-submit {
    max-width: 347px;
    padding: 26px;
    font-size: 2rem;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    @media screen and (max-width: 767px) {
      padding: 18px;
      font-size: 1.4rem;
    }
  }

  .form-block__bg {
    position: absolute;
    left: -24px;
    bottom: -266px;
    z-index: 0;
    @media screen and (max-width: 1199px) {
      left: 50%;
      bottom: -330px;
      transform: translate(-50%, 0);
    }
  }

}



.form-input {
  padding: 25px 20px;
  font-size: 2rem;
  font-weight: 600;
  line-height: 150%;
  border-radius: 5px;
  border: 1px solid rgba(255, 255, 255, 0.10);
  background: rgba(255, 255, 255, 0.10);
  color: $white;
  &::placeholder {
    color: $white;
  }
  @media screen and (max-width: 767px) {
    padding: 17px 14px;
    font-size: 1.4rem;
  }
}

.upload-btn {
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: 600;
  line-height: 150%;
  cursor: pointer;
  transition: .3s;
  &:hover {
    .upload-icon {
      background: #ff0000;
    }
  }
  @media screen and (max-width: 767px) {
    font-size: 1.4rem;
  }
  .upload-icon {
    position: relative;
    display: block;
    width: 41px;
    height: 41px;
    background-color: $red;
    border-radius: 4px;
    transition: .3s;
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: $white;
    }

    &:before {
      width: 50%;
      height: 4px;
    }
    &:after {
      width: 4px;
      height: 50%;
    }
    @media screen and (max-width: 767px) {
      width: 30px;
      height: 30px;
    }
  }

  .upload-name {
    display: block;
    position: relative;
    margin-left: 10px;
    &:before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      opacity: 0.5;
      border-bottom: 2px dashed $white;
    }
  }
}

.catalog .form-body .form-input {
  &.invalid {
    color: red;
    border-bottom: 1px solid red;
  }
}

.form-block .form-input,
.quality .form-body .form-input,
.modal-calculation .form-input,
.modal-callback .form-input  {
  &.invalid {
    color: red;
    border: 1px solid red;
  }
}